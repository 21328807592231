import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Button, MoneyInput, PercentInput } from '@refera/ui-web'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Paper,
  Slide,
  Typography,
} from '@material-ui/core'

import useStyles from './styles'
import { APPLY_TOAST_MESSAGE, items, VISUALIZATION_TOAST_MESSAGE } from './constants'
import { displayCurrency, displayPercentage } from '../utils'
import Draggable from 'react-draggable'
import IconButton from '_/components/svg/icon-button'
import CloseIcon from '@material-ui/icons/Close'
import classNames from 'classnames'
import { round } from '_/views/finance/utils/functions'
import { useToast } from '_/hooks/use-toast'
import { useDialog } from '_/hooks/use-dialog'

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const PaperComponent = props => {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  )
}

export function ReferaTaxesModal({ budget, handleInputChange, open, close, isVisualization }) {
  const styles = useStyles()
  const [inputValue, setInputValue] = useState()
  const [inputPercentage, setInputPercentage] = useState()
  const [initialBudget] = useState(budget)
  const { showToast } = useToast()
  const { showDialog, closeDialog } = useDialog()

  const referaCompletionValue = useMemo(() => {
    return (
      budget?.referaRegularTax +
      budget?.additionalTakeRate +
      budget?.warrantyValue +
      budget?.revenueShare
    )
  }, [budget])

  const referaCompletionPercentage = useMemo(() => {
    return round((referaCompletionValue / budget?.totalPriceTradesman) * 100, 8)
  }, [budget, referaCompletionValue])

  const totalPrice = useMemo(() => {
    return (
      budget?.totalPriceTradesman +
      budget?.agencyCompletionPercentageValue +
      referaCompletionValue +
      budget?.financialIncomeValue
    )
  }, [
    referaCompletionValue,
    budget?.agencyCompletionPercentageValue,
    budget?.totalPriceTradesman,
    budget?.financialIncomeValue,
  ])

  const handleChangeValues = useCallback(
    (item, type) => {
      let percentageValue = 0
      let input = 0
      let percentageEvent
      let valueEvent

      if (type === 'value') {
        if (inputValue?.floatValue) {
          percentageValue = (inputValue?.floatValue / budget.totalPriceTradesman) * 100 || 0
          input = inputValue?.floatValue
        }

        if (
          inputValue?.item?.name === item.name &&
          input !== Number(parseFloat(budget[item.name])?.toFixed(2))
        ) {
          percentageEvent = {
            target: { name: item.complementName, value: round(percentageValue, 4) },
          }
          valueEvent = {
            target: { name: item.name, value: round(input, 2) || 0 },
          }
        }
      }
      if (type === 'percentage') {
        if (inputPercentage?.floatValue) {
          percentageValue = (inputPercentage?.floatValue / 100.0) * budget.totalPriceTradesman || 0
          input = inputPercentage?.floatValue
        }
        if (
          inputPercentage?.item?.name === item.name &&
          input !== Number(parseFloat(budget[item.name])?.toFixed(2))
        ) {
          percentageEvent = {
            target: { name: item.complementName, value: round(percentageValue, 2) },
          }
          valueEvent = {
            target: {
              name: item.name,
              value: Number(round(input, 4)) || 0,
            },
          }
        }
      }

      if (percentageEvent && valueEvent) {
        handleInputChange(percentageEvent)
        handleInputChange(valueEvent)
      }
    },
    [handleInputChange, budget, inputValue, inputPercentage]
  )

  const renderTotalPriceTradesman = useMemo(() => {
    return (
      <Grid className={classNames(styles.headerWrapper, styles.black, styles.bold)}>
        <span className={classNames(styles.label, styles.black)} style={{ fontSize: '16px' }}>
          Valor prestador:
        </span>
        <span className={classNames(styles.value, styles.black)}>
          {displayCurrency(budget.totalPriceTradesman)}
        </span>
      </Grid>
    )
  }, [budget.totalPriceTradesman])

  const renderItems = useMemo(() => {
    return items({ budget, styles })?.map(item => {
      const columns = item.fields.length
      return (
        <Grid key={item.label} className={styles.item}>
          <Typography className={styles.label}>{item?.label}</Typography>
          {columns === 1 && <div />} {/* To push solo items to the end of the grid */}
          {item.fields.map(field => {
            const { value, type } = field

            if (type === 'percentage-input') {
              return (
                <PercentInput
                  key={field.name}
                  value={displayPercentage(value ?? 0)}
                  mask=" "
                  format="####"
                  placeholder="%"
                  allowNegative={false}
                  allowLeadingZeros={false}
                  className={styles.input}
                  onBlur={() => handleChangeValues(field, 'percentage')}
                  onChange={({ floatValue }) =>
                    setInputPercentage({ floatValue: floatValue ?? 0, item: field })
                  }
                  style={{ fontSize: '16px', textAlign: 'right' }}
                  disabled={isVisualization}
                />
              )
            }
            if (type === 'currency-input') {
              return (
                <MoneyInput
                  key={field.name}
                  value={displayPercentage(value ?? 0)}
                  style={{ fontSize: '16px' }}
                  placeholder="R$ "
                  allowNegative={false}
                  allowLeadingZeros={false}
                  className={styles.input}
                  onBlur={() => handleChangeValues(field, 'value')}
                  onChange={({ floatValue }) =>
                    setInputValue({ floatValue: floatValue ?? 0, item: field })
                  }
                  disabled={isVisualization}
                />
              )
            }
            if (type === 'currency-read') {
              return (
                <Typography key={field.name} className={styles.readField}>
                  {displayCurrency(value)}
                </Typography>
              )
            }
            return null
          })}
        </Grid>
      )
    })
  }, [
    budget,
    items,
    isVisualization,
    styles,
    setInputPercentage,
    setInputValue,
    handleChangeValues,
  ])

  const renderReferaCompletionValues = useMemo(() => {
    return (
      <Grid className={styles.item} style={{ padding: '0 24px', marginTop: '12px' }}>
        <span className={classNames(styles.label, styles.black, styles.bold)}>
          Tarifa da refera:
        </span>
        <span className={classNames(styles.value, styles.black)}>
          {displayPercentage(referaCompletionPercentage)}
        </span>
        <span className={classNames(styles.value, styles.black)}>
          {displayCurrency(referaCompletionValue)}
        </span>
      </Grid>
    )
  }, [referaCompletionPercentage, referaCompletionValue])

  const renderSoloItem = useMemo(
    () => (name, value, style) => {
      return (
        <Grid className={styles.soloItem} style={style}>
          <span className={classNames(styles.label, styles.black, styles.bold)}>{name}</span>
          <span className={classNames(styles.value, styles.black, styles.soloItemValue)}>
            {value}
          </span>
        </Grid>
      )
    },
    []
  )

  const renderFinalValue = useMemo(() => {
    return (
      <Grid className={styles.finalItem}>
        <span className={classNames(styles.label, styles.black)} style={{ fontSize: '20px' }}>
          Valor bruto: {displayCurrency(totalPrice)}
        </span>
      </Grid>
    )
  }, [totalPrice])

  const handleUpdateFinalValues = useCallback(() => {
    const itemsToUpdate = [
      { name: 'referaCompletionPercentage', value: referaCompletionPercentage },
      { name: 'referaCompletionPercentageValue', value: referaCompletionValue },
      { name: 'totalPrice', value: totalPrice },
    ]

    itemsToUpdate.forEach(item => {
      handleInputChange({ target: { name: item.name, value: item.value } })
    })
  }, [budget, referaCompletionPercentage, referaCompletionValue, totalPrice, handleInputChange])

  const handleApply = useCallback(() => {
    showToast({
      type: 'warning',
      message: APPLY_TOAST_MESSAGE,
    })
    close()
  }, [close, showToast])

  const handleResetFieldsAndCloseModal = useCallback(() => {
    // Restores budget to its initial state
    Object.keys(initialBudget).forEach(key => {
      if (initialBudget[key] !== budget[key]) {
        handleInputChange({ target: { name: key, value: initialBudget[key] } })
      }
    })
    closeDialog()
    close()
  }, [initialBudget, budget, handleInputChange, close, closeDialog])

  const handleCancel = useCallback(() => {
    showDialog({
      type: 'warning',
      subject: 'Você tem certeza que deseja fechar a janela sem salvar o registro?',
      onApprove: handleResetFieldsAndCloseModal,
      onCancel: closeDialog,
      labelCancel: 'Não',
      labelApprove: 'Sim',
    })
  }, [handleResetFieldsAndCloseModal, showDialog, closeDialog])

  const renderDialogActions = useMemo(() => {
    return isVisualization ? (
      <DialogActions className={styles.dialogActions}>
        <Button onClick={close} variant="secondary" color="red">
          Fechar
        </Button>
      </DialogActions>
    ) : (
      <DialogActions className={styles.dialogActions}>
        <Button onClick={handleCancel} variant="secondary" color="red">
          Cancelar
        </Button>
        <Button onClick={handleApply} color="primary">
          Aplicar
        </Button>
      </DialogActions>
    )
  }, [isVisualization, close, handleCancel, handleApply])

  useEffect(() => {
    if (isVisualization) {
      showToast({
        type: 'info',
        message: VISUALIZATION_TOAST_MESSAGE,
      })
    }
  }, [isVisualization])

  useEffect(() => {
    handleUpdateFinalValues()
  }, [referaCompletionPercentage])

  return (
    <Dialog
      open={open}
      onClose={close}
      className={styles.dialog}
      PaperComponent={PaperComponent}
      TransitionComponent={Transition}
      aria-describedby="Modal de tarifas da refera"
    >
      <IconButton onClick={handleCancel} className={styles.closeButton}>
        <CloseIcon />
      </IconButton>
      <DialogTitle id="draggable-dialog-title" className={styles.title}>
        Tarifas da refera
      </DialogTitle>
      <Divider />
      <DialogContent className={styles.content}>
        {renderTotalPriceTradesman}
        <Divider />
        <Grid className={styles.itemsContainer}>{renderItems}</Grid>
        <Divider />
        {renderReferaCompletionValues}
        <Grid className={styles.finalPricesWrapper} style={{ paddingBottom: '12px' }}>
          {renderSoloItem('Tarifa da intermediária:', displayCurrency(budget?.priceAgency))}
          {renderSoloItem('Receita financeira:', displayCurrency(budget?.financialIncomeValue))}
        </Grid>
        <Grid className={styles.finalPricesWrapper}>{renderFinalValue}</Grid>
      </DialogContent>
      {renderDialogActions}
    </Dialog>
  )
}
